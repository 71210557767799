import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { push } from 'redux-first-history';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import startCase from 'lodash/startCase';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

function ViewStack(props) {
  const { selectedView, views, justifyContent = 'flex-start' } = props;
  const dispatch = useDispatch();
  const location = useLocation();

  const handleClick = (view) => {
    if (view !== selectedView) {
      const url = location.pathname + location.search + `#${view}`;
      dispatch(push(url));
    }
  };

  return (
    <Stack direction='row' justifyContent={justifyContent} alignItems='center'>
      {map(views, (view, idx) => {
        return (
          <Link
            key={view + idx}
            onClick={() => handleClick(view)}
            variant='h6'
            align='left'
            ml={idx === 0 ? 1 : 2}
            underline={view === selectedView ? 'none' : 'hover'}
            sx={{ fontWeight: view === selectedView ? 800 : 100 }}>
            {startCase(view)}
          </Link>
        );
      })}
    </Stack>
  );
}

ViewStack.propTypes = {
  selectedView: PropTypes.string,
  setSelectedView: PropTypes.func,
  views: PropTypes.array,
  justifyContent: PropTypes.string,
};

export default ViewStack;
