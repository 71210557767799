import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import Grid from '@mui/material/Grid2';

import LoggerCard from './LoggerCard';

function LoggersGrid(props) {
  const { site, loggerIds } = props;

  return map(loggerIds, (loggerId) => {
    return (
      <Grid key={loggerId} size={{ xs: 12 }}>
        <LoggerCard
          orgId={site.org_id}
          loggerId={loggerId}
          timezone={site.timezone}
        />
      </Grid>
    );
  });
}

LoggersGrid.propTypes = {
  site: PropTypes.object,
  loggerIds: PropTypes.array,
};

export default LoggersGrid;
