import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';

import Link from '@mui/material/Link';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

import { navigate } from '../../../store/pages';

export const SiteIdNameLinkTypeProvider = (props) => {
  const dispatch = useDispatch();
  const { data: sites } = useSelector((state) => state.sites);

  const handleClick = (siteId) => {
    dispatch(navigate({ page: 'site', id: siteId }));
  };

  return (
    <DataTypeProvider
      formatterComponent={({ value: siteId }) => {
        const site = find(sites, { site_id: siteId });
        return (
          <Link underline='hover' onClick={() => handleClick(siteId)}>
            {site.name}
          </Link>
        );
      }}
      {...props}
    />
  );
};
