import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { common } from '@mui/material/colors';
import { useColorScheme } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import styled from '@mui/material/styles/styled';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { setDrawerExpanded } from '../../../store/app';
import { logoutUser } from '../../../store/user';
import Breadcrumbs from './Breadcrumbs';
import ROUTES from '../../../constants/routes';

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => !['expanded', 'drawerWidth'].includes(prop),
})(({ expanded, theme, drawerWidth }) => ({
  boxShadow: 'unset',
  [theme.breakpoints.up('md')]: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: expanded
        ? theme.transitions.duration.enteringScreen
        : theme.transitions.duration.leavingScreen,
    }),
  },
}));

const DrawerToggleButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(0),
  [theme.breakpoints.up('md')]: {},
  [theme.breakpoints.down('md')]: {
    marginLeft: '-4px',
  },
}));

const CustomAppBar = forwardRef((props, ref) => {
  const { setMobileOpen } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const { mode, setMode } = useColorScheme();
  const { drawerExpanded } = useSelector((state) => state.app);
  const user = useSelector((state) => state.user.item);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const toggleMenu = () => {
    if (isMobile) setMobileOpen((currentOpen) => !currentOpen);
    else dispatch(setDrawerExpanded(!drawerExpanded));
  };

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const drawerWidth = drawerExpanded ? theme.drawerWidth : 0;
  return (
    <StyledAppBar
      ref={ref}
      position='fixed'
      drawerWidth={drawerWidth}
      expanded={drawerExpanded}>
      <Toolbar
        sx={{
          backgroundColor: 'primary.main',
        }}>
        <DrawerToggleButton color='inherit' edge='start' onClick={toggleMenu}>
          <FontAwesomeIcon
            icon={[
              'fal',
              !isMobile && drawerExpanded ? 'arrow-to-left' : 'bars',
            ]}
            size='sm'
          />
        </DrawerToggleButton>
        <Breadcrumbs />
        {!isMobile && (
          <Typography
            noWrap
            align='right'
            sx={{ flexGrow: 1, mr: 2, cursor: 'pointer' }}
            onClick={() => dispatch(push(ROUTES.AUTH.PROFILE))}>
            {user?.email}
          </Typography>
        )}
        <IconButton aria-label='open popover' onClick={handleLogout}>
          <FontAwesomeIcon
            icon={['fal', 'sign-out']}
            color={common.white}
            size='sm'
          />
        </IconButton>
        <IconButton
          onClick={() => setMode(mode === 'light' ? 'dark' : 'light')}
          sx={{ height: 32, width: 32 }}>
          <FontAwesomeIcon
            icon={['fal', mode === 'light' ? 'sun' : 'moon']}
            color={common.white}
            size='sm'
          />
        </IconButton>
        <IconButton href='https://support.veregy.com/section/102-lightlevel'>
          <FontAwesomeIcon
            icon={['fal', 'question-circle']}
            color={common.white}
            size='sm'
          />
        </IconButton>
      </Toolbar>
    </StyledAppBar>
  );
});

export default CustomAppBar;
