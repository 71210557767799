import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import remove from 'lodash/remove';

import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';

import { GOOGLE_MAPS_API_KEY } from '../../constants/google';
import ComponentLoader from '../Loaders/ComponentLoader';
import useFitBounds from '../../store/hooks/useFitBounds';
import { navigate } from '../../store/pages';
import { currentGeneration } from '../../helpers/chart-data';
import {
  thisMonthActual,
  thisMonthExpected,
} from '../../helpers/performance-data';
import InfoWindow from './InfoWindow';

const getResourceId = (resource) => {
  if (resource.type_ === 'organization') {
    return resource.org_id;
  } else return get(resource, `${resource.type_}_id`);
};

function Map(props) {
  const { center, locations, styles } = props;
  const dispatch = useDispatch();
  const loggers = useSelector((state) => state.loggers.data);
  const sites = useSelector((state) => state.sites.data);
  const meters = useSelector((state) => state.meters.data);
  const inverters = useSelector((state) => state.inverters.data);
  const timeseries = useSelector((state) => state.timeseries.data);
  const [selected, setSelected] = useState([]);
  const [markerMap, setMarkerMap] = useState({});
  const [fitBoundsOnLoad, onMapUnmount] = useFitBounds(locations);

  const { isLoaded } = useLoadScript({
    id: 'script-loader',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    language: 'en-us',
  });

  const getResource = (resourceId) => {
    if (resourceId.startsWith('org:')) {
      return find(locations, { org_id: resourceId });
    } else {
      return find(locations, { site_id: resourceId });
    }
  };

  const navigateToResource = (resource) => {
    dispatch(navigate({ page: resource.type_, id: getResourceId(resource) }));
  };

  const markerLoadHandler = (marker, resource) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [getResourceId(resource)]: marker };
    });
  };

  const markerClickHandler = (event, resource) => {
    const resourceId = getResourceId(resource);
    if (includes(selected, resourceId)) {
      onClose(resource);
    } else {
      setSelected([...selected, resourceId]);
    }
  };

  const onClose = (resource) => {
    const resourceId = getResourceId(resource);
    let _selected = cloneDeep(selected);
    remove(_selected, (id) => id === resourceId);
    setSelected(_selected);
  };

  const renderLocations = () => {
    if (isEmpty(locations)) {
      return null;
    } else {
      return locations.map((resource) => {
        let key = getResourceId(resource);
        return (
          <MarkerF
            key={key}
            position={resource.location}
            onLoad={(marker) => markerLoadHandler(marker, resource)}
            onClick={(event) => markerClickHandler(event, resource)}
          />
        );
      });
    }
  };

  const renderOrganizationInfo = (organization) => {
    let orgSites = filter(sites, { org_id: organization.org_id });
    let orgLoggers = filter(loggers, { org_id: organization.org_id });
    let orgMeters = filter(meters, { org_id: organization.org_id });
    let orgInverters = filter(inverters, { org_id: organization.org_id });
    let expectations = map(orgSites, (site) => site.modeled_expectations);
    const meterIds = orgMeters.map((meter) => meter.meter_id);
    const timeseriesData = timeseries.filter((item) => {
      return meterIds.includes(item.device_id) && item.data_type === 'recent';
    });

    let actual = thisMonthActual(orgMeters);
    let expected = thisMonthExpected(expectations);

    let generation = currentGeneration(timeseriesData);
    let capacity = reduce(
      orgInverters,
      (acc, inverter) => {
        const meter = find(orgMeters, { meter_id: inverter.meter_id });
        const logger = find(orgLoggers, { logger_id: meter.logger_id });
        if (logger.active) {
          return acc + get(inverter, 'ac_size', 0);
        } else {
          return acc;
        }
      },
      0
    );

    return (
      <InfoWindow
        key={organization.org_id}
        resource={organization}
        generation={generation}
        capacity={capacity}
        actual={actual}
        expected={expected}
        markerMap={markerMap}
        onClose={onClose}
        onClick={navigateToResource}
      />
    );
  };

  const renderSiteInfo = (site) => {
    let siteLoggers = filter(loggers, { site_id: site.site_id });
    let siteMeters = filter(meters, { site_id: site.site_id });
    let meterIds = map(siteMeters, (meter) => meter.meter_id);
    let siteInverters = filter(inverters, (inverter) =>
      includes(meterIds, inverter.meter_id)
    );
    const timeseriesData = timeseries.filter((item) => {
      return meterIds.includes(item.device_id) && item.data_type === 'recent';
    });

    let actual = thisMonthActual(siteMeters);
    let expected = thisMonthExpected([site.modeled_expectations]);

    let generation = currentGeneration(timeseriesData);
    let capacity = reduce(
      siteInverters,
      (acc, inverter) => {
        const meter = find(siteMeters, { meter_id: inverter.meter_id });
        const logger = find(siteLoggers, { logger_id: meter.logger_id });
        if (logger.active) {
          return acc + get(inverter, 'ac_size', 0);
        } else {
          return acc;
        }
      },
      0
    );
    return (
      <InfoWindow
        key={site.site_id}
        resource={site}
        generation={generation}
        capacity={capacity}
        actual={actual}
        expected={expected}
        markerMap={markerMap}
        onClose={onClose}
        onClick={navigateToResource}
      />
    );
  };

  const renderSelected = () => {
    return map(selected, (resourceId) => {
      const resource = getResource(resourceId);
      switch (resource?.type_) {
        case 'organization':
          return renderOrganizationInfo(resource);

        case 'site':
          return renderSiteInfo(resource);

        default:
          return null;
      }
    });
  };

  if (!isLoaded) return <ComponentLoader />;
  return (
    <GoogleMap
      id='portfolio-map'
      onLoad={(map) => {
        fitBoundsOnLoad(map);
      }}
      onUnmount={onMapUnmount}
      mapContainerStyle={styles}
      zoom={17}
      center={center.location}>
      {renderLocations()}
      {renderSelected()}
    </GoogleMap>
  );
}

Map.propTypes = {
  center: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
};

export default Map;
