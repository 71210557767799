import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import {
  Area,
  ComposedChart,
  Label,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { prepareSensorTimeseriesData } from '../../../../helpers/chart-data';
import { navigate } from '../../../../store/pages';
import ChartTooltip from './Tooltip';
import ComponentLoader from '../../../Loaders/ComponentLoader';

function SensorChart({ site, sensor, hideAxisLabels = true }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { data: timeseries, loading: timeseriesLoading } = useSelector(
    (state) => state.timeseries
  );
  const { loading: sensorLoading } = useSelector((state) => state.sensors);
  const loading = timeseriesLoading || sensorLoading;

  const [chartData, setChartData] = useState([]);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  useEffect(() => {
    const zone = site?.timezone?.zone;

    if (zone) {
      const _start = dayjs().tz(zone).startOf('day').subtract(1, 'day');
      setStart(_start);
      setEnd(_start.add(2, 'day'));
    }
  }, [site?.timezone?.zone]);

  // set chartData
  useEffect(() => {
    if (!isEmpty(sensor) && start && end) {
      const recentTimeseries = timeseries.find((item) => {
        return (
          item.device_id === sensor.sensor_id && item.data_type === 'recent'
        );
      });
      setChartData(
        prepareSensorTimeseriesData(
          {
            sensor_id: sensor.sensor_id,
            records: recentTimeseries?.data,
          },
          { start, end }
        )
      );
    }
  }, [sensor, timeseries, start, end]);

  const handleClick = () => {
    dispatch(
      navigate({ page: 'sensor', id: sensor.sensor_id, tab: 'analysis' })
    );
  };

  const height = isMobile ? 150 : 175;
  if (!start || !end || chartData.length === 0 || loading)
    return (
      <div style={{ height }}>
        <ComponentLoader height={50} width={50} />
      </div>
    );

  const startRef = start?.add(6, 'hour');
  const endRef = start?.add(30, 'hour');
  return (
    <ResponsiveContainer
      key={`chart-${sensor.sensor_id}`}
      width='100%'
      height={height}>
      <ComposedChart
        onClick={handleClick}
        width='100%'
        height={height}
        data={chartData}
        margin={{
          top: 2,
          right: 2,
          left: 2,
          bottom: 0,
        }}>
        <YAxis
          mirror
          yAxisId='temp'
          orientation='right'
          domain={[
            (dataMin) => Math.min(0, dataMin),
            (dataMax) => Math.ceil(dataMax / 10) * 10,
          ]}
          tickFormatter={(val, _axis) => {
            return numeral(val.toPrecision(4)).format('0,0.[000]') + ' °C';
          }}
          hide={hideAxisLabels}
        />
        <YAxis
          mirror
          yAxisId='radiation'
          domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]}
          width={100}
          tickFormatter={(val, _axis) => {
            return numeral(val.toPrecision(4)).format('0,0.[000]') + ' W/m^2';
          }}
          hide={hideAxisLabels}
        />
        <XAxis
          type='number'
          dataKey='timestamp'
          domain={[start?.unix(), end?.unix()]}
          hide
        />
        <ReferenceLine
          yAxisId='radiation'
          x={startRef.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft' offset={20}>
              {startRef.format('MMM Do')}
            </Label>
          }
        />
        <ReferenceLine
          yAxisId='radiation'
          x={endRef.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft' offset={20}>
              {endRef.format('MMM Do')}
            </Label>
          }
        />
        <Tooltip content={<ChartTooltip timezone={site.timezone} />} />
        <Area
          yAxisId='radiation'
          key={`radiation-area-${sensor.sensor_id}`}
          dataKey={`${sensor.sensor_id}:Radiation`}
          stroke={theme.veregy_colors.darkYellow}
          fill={theme.veregy_colors.darkYellow}
          fillOpacity={0.7}
        />
        <Line
          yAxisId='temp'
          dot={false}
          key={`temp-line-${sensor.sensor_id}`}
          dataKey={`${sensor.sensor_id}:PanelTemp`}
          stroke={theme.veregy_colors.purple}
          connectNulls
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

SensorChart.propTypes = {
  site: PropTypes.object,
  sensor: PropTypes.object,
  hideAxisLabels: PropTypes.bool,
};

export default SensorChart;
