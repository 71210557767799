import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import each from 'lodash/each';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';

import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';

import {
  Area,
  AreaChart,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import ChartTooltip from './Tooltip';
import { navigate } from '../../../../store/pages';
import ComponentLoader from '../../../Loaders/ComponentLoader';

function InvertersProfileChart({ site, logger, inverters }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { data: timeseries, loading: timeseriesLoading } = useSelector(
    (state) => state.timeseries
  );
  const { loading: invertersLoading } = useSelector((state) => state.inverters);
  const loading = timeseriesLoading || invertersLoading;

  const [data, setData] = useState([]);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  useEffect(() => {
    const zone = site?.timezone?.zone;

    if (zone) {
      const _start = dayjs().tz(zone).startOf('day').subtract(1, 'day');
      setStart(_start);
      setEnd(_start.add(2, 'day'));
    }
  }, [site?.timezone?.zone]);

  useEffect(() => {
    if (inverters.length && start && end) {
      let _data = map(range(start.unix(), end.unix(), 900), (timestamp) => ({
        timestamp,
      }));

      const inverterIds = inverters.map((inverter) => inverter.inverter_id);
      const inverterTimeseriesData = timeseries.filter((item) => {
        return (
          inverterIds.includes(item.device_id) && item.data_type === 'recent'
        );
      });

      setData(
        map(_data, (record) => {
          const timestamp = get(record, 'timestamp');
          each(inverterTimeseriesData, (item) => {
            let inverterPoint = find(item.data, { timestamp });
            record[item.device_id] = get(inverterPoint, 'value');
          });
          return { ...record };
        })
      );
    }
  }, [inverters, timeseries, start, end]);

  const handleClick = () => {
    dispatch(
      navigate({ page: 'logger', id: logger.logger_id, tab: 'analysis' })
    );
  };

  const height = isMobile ? 150 : 175;
  if (!start || !end || data.length === 0 || loading)
    return (
      <div style={{ height }}>
        <ComponentLoader height={50} width={50} />
      </div>
    );

  let startReference = start.add(6, 'hour');
  let endReference = start.add(30, 'hour');
  return (
    <ResponsiveContainer width='100%' height={height}>
      <AreaChart
        onClick={handleClick}
        width='100%'
        height={height}
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}>
        <YAxis hide domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]} />
        <XAxis
          type='number'
          dataKey='timestamp'
          domain={[start.unix(), end.unix()]}
          hide
        />
        <ReferenceLine
          x={startReference.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft'>
              {startReference.format('MMM Do')}
            </Label>
          }
        />
        <ReferenceLine
          x={endReference.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft'>
              {endReference.format('MMM Do')}
            </Label>
          }
        />
        <Tooltip
          content={
            <ChartTooltip timezone={site.timezone} inverters={inverters} />
          }
        />
        {map(sortBy(inverters, 'parent_index'), (inverter) => {
          return (
            <Area
              dot={false}
              stackId='area'
              key={`line-${inverter.inverter_id}`}
              dataKey={inverter.inverter_id}
              stroke={theme.veregy_colors.orange}
              strokeOpacity={0.7}
              fill='transparent'
              connectNulls
              strokeWidth={2}
            />
          );
        })}
      </AreaChart>
    </ResponsiveContainer>
  );
}

InvertersProfileChart.propTypes = {
  site: PropTypes.object.isRequired,
  logger: PropTypes.object.isRequired,
  inverters: PropTypes.array.isRequired,
};

export default InvertersProfileChart;
