import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'redux-first-history';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { logoutUser } from '../../store/user';
import ROUTES from '../../constants/routes';

const AUTH_URL = process.env.REACT_APP_AUTH_URL;
const CLIENT_ID = process.env.REACT_APP_USER_POOL_WEBCLIENT_ID;

const Div = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '6px',
  };
});

export default function AuthenticationLinks() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.item);

  const handleLogInClick = (e) => {
    e.preventDefault();
    const url = `${AUTH_URL}login?client_id=${CLIENT_ID}`;
    window.location.href = url;
  };

  const handleSignUpClick = (e) => {
    e.preventDefault();
    const url = `${AUTH_URL}signup?client_id=${CLIENT_ID}`;
    window.location.href = url;
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        backgroundImage: (theme) => theme.vars.overlays[8],
        boxShadow: (theme) => theme.shadows[4],
        borderRadius: 1,
        px: 4,
        py: 2,
      }}>
      <Box
        component='img'
        sx={{
          display: 'flex',
          mb: 1,
        }}
        alt='LightLevel by Veregy'
        src='/images/LightLevel_by_Veregy.svg'
        title='LightLevel'
      />
      <Typography variant='h5' align='left' sx={{ my: 1 }}>
        Real-time insights to power a sustainable future.
      </Typography>
      {user ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
          }}>
          <Button
            type='submit'
            color='secondary'
            variant='contained'
            onClick={(e) => {
              e.preventDefault();
              dispatch(push(ROUTES.AUTH.PORTFOLIO));
            }}
            sx={{ width: '40%' }}>
            Dashboard
          </Button>
          <Div>
            <Typography variant='body2' sx={{ mr: 1 }}>
              Logged in as {user.email}
            </Typography>
            <Link
              underline='hover'
              onClick={(e) => {
                e.preventDefault();
                dispatch(logoutUser());
              }}
              fontWeight='bold'
              variant='body2'>
              Log out
            </Link>
          </Div>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
          }}>
          <Button
            type='submit'
            color='secondary'
            variant='contained'
            onClick={handleLogInClick}
            sx={{ width: '40%' }}>
            Log in
          </Button>
          <Div>
            <Typography variant='body2' sx={{ mr: 1 }}>
              Need an account?
            </Typography>
            <Link
              underline='hover'
              onClick={handleSignUpClick}
              fontWeight='bold'
              color='textSecondary'
              variant='body2'>
              Sign Up
            </Link>
          </Div>
        </Box>
      )}
    </Box>
  );
}
