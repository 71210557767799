import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import useMediaQuery from '@mui/material/useMediaQuery';
import Alert from '@mui/material/Alert';

import usePageId from '../../../store/hooks/usePageId';
import useOrganization from '../../../store/hooks/useOrganization';
import { navigate, setWebsocketOrgId } from '../../../store/pages';
import useTimeseriesData from '../../../store/hooks/useTimeseriesData';
import useLicense, { STANDARD_LICENSE } from '../../../store/hooks/useLicense';

import Dashboard from './Dashboard';
import Configuration from './Configuration';
import Analysis from './Analysis';
import TabBar, {
  ANALYSIS,
  CONFIGURATION,
  DASHBOARD,
} from '../../../components/tab/TabBar';
import useTab from '../../../store/hooks/useTab';

const PAGE = 'organization';
const TABS = [DASHBOARD, ANALYSIS, CONFIGURATION];

export default function Organization() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const user = useSelector((state) => state.user.item);
  const { id } = useSelector((state) => state.pages.organization);
  const license = useLicense(id, STANDARD_LICENSE);
  const { organization } = useOrganization(id);
  usePageId(id, PAGE);
  useTimeseriesData(id);

  const [tabs, setTabs] = useState([DASHBOARD, CONFIGURATION]);
  const selectedTab = useTab(tabs);

  useEffect(() => {
    if (id) dispatch(setWebsocketOrgId(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (isMobile) setTabs([DASHBOARD, CONFIGURATION]);
    else setTabs(TABS);
  }, [isMobile]);

  useEffect(() => {
    if (!id && user?.default_organization) {
      dispatch(navigate({ page: PAGE, id: user.default_organization }));
    }
  }, [dispatch, id, user]);

  if (license?.org_id && !license.effectiveStatus) {
    return (
      <Alert severity='error' sx={{ m: 2 }}>
        {get(organization, 'name', 'This Organization')}&apos;s Standard License
        is inactive. Contact an administrator for further assistance.
      </Alert>
    );
  }

  return (
    <TabBar tabs={tabs} selectedTab={selectedTab}>
      <Dashboard tab={DASHBOARD} />
      <Analysis tab={ANALYSIS} />
      <Configuration tab={CONFIGURATION} />
    </TabBar>
  );
}
