import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';

import Link from '@mui/material/Link';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

import { navigate } from '../../../store/pages';

export const MeterIdNameLinkTypeProvider = (props) => {
  const dispatch = useDispatch();
  const { data: meters } = useSelector((state) => state.meters);

  const handleClick = (meterId) => {
    dispatch(navigate({ page: 'meter', id: meterId }));
  };

  return (
    <DataTypeProvider
      formatterComponent={({ value: meterId }) => {
        const meter = find(meters, { meter_id: meterId });
        return (
          <Link underline='hover' onClick={() => handleClick(meterId)}>
            {meter.name}
          </Link>
        );
      }}
      {...props}
    />
  );
};
