import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import dayjs from 'dayjs';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import {
  Bar,
  ComposedChart,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { navigate } from '../../../../store/pages';
import { prepareInverterChartData } from '../../../../helpers/chart-data';
import ChartTooltip from './Tooltip';
import ComponentLoader from '../../../Loaders/ComponentLoader';

function InverterChart(props) {
  const { site, inverter } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const theme = useTheme();
  const { data: timeseries, loading: timeseriesLoading } = useSelector(
    (state) => state.timeseries
  );
  const { loading: inverterLoading } = useSelector((state) => state.inverters);
  const loading = timeseriesLoading || inverterLoading;

  const [chartData, setChartData] = useState([]);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  useEffect(() => {
    const zone = site?.timezone?.zone;

    if (zone) {
      const _start = dayjs().tz(zone).startOf('day').subtract(1, 'day');
      setStart(_start);
      setEnd(_start.add(2, 'day'));
    }
  }, [site?.timezone?.zone]);

  // set chartData
  useEffect(() => {
    if (!isEmpty(inverter) && start && end) {
      const recentTimeseries = timeseries.find((item) => {
        return (
          item.device_id === inverter.inverter_id && item.data_type === 'recent'
        );
      });
      setChartData(
        prepareInverterChartData(
          {
            inverter_id: inverter.inverter_id,
            records: recentTimeseries?.data,
          },
          { start, end }
        )
      );
    }
  }, [inverter, start, end, timeseries]);

  const handleClick = () => {
    dispatch(
      navigate({ page: 'inverter', id: inverter.inverter_id, tab: 'analysis' })
    );
  };

  const height = isMobile ? 150 : 175;
  if (chartData.length === 0 || !start || !end || loading)
    return (
      <div style={{ height }}>
        <ComponentLoader height={50} width={50} />
      </div>
    );
  let startReference = start.add(6, 'hour');
  let endReference = start.add(30, 'hour');
  return (
    <ResponsiveContainer
      key={`chart-${inverter.inverter_id}`}
      width='100%'
      height={height}>
      <ComposedChart
        onClick={handleClick}
        width='100%'
        height={height}
        data={chartData}
        margin={{
          top: 2,
          right: 0,
          left: 2,
          bottom: 0,
        }}>
        <YAxis
          mirror
          domain={[
            0,
            (dataMax) =>
              dataMax > get(inverter, 'ac_size', 0)
                ? dataMax + 5
                : get(inverter, 'ac_size', 0) + 5,
          ]}
          tickFormatter={(val, _axis) => {
            return numeral(val.toPrecision(4)).format('0,0.[0]') + ' kW';
          }}
        />
        <XAxis
          type='number'
          dataKey='timestamp'
          domain={[start.unix(), end.unix()]}
          hide
        />
        <ReferenceLine y={0} stroke={theme.veregy_colors.lightgrey} />
        <ReferenceLine
          x={startReference.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft'>
              {startReference.format('MMM Do')}
            </Label>
          }
        />
        <ReferenceLine
          x={endReference.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft'>
              {endReference.format('MMM Do')}
            </Label>
          }
        />
        <Tooltip
          content={
            <ChartTooltip timezone={site?.timezone} inverter={inverter} />
          }
        />

        <Bar
          key={`bar-${inverter.inverter_id}`}
          dataKey={inverter.inverter_id}
          fill={theme.veregy_colors.orange}
          stroke='transparent'
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

InverterChart.propTypes = {
  site: PropTypes.object,
  inverter: PropTypes.object,
};

export default InverterChart;
